import { useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination, Autoplay } from 'swiper/modules';
import { Loader } from '../Loader';
import { ArtistsService } from '../../client';
import { useQuery } from 'react-query';
import { Avatar, Box, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import slugify from 'react-slugify';
import { generateCldUrl } from '../../utils';

export default function FeaturedArtists() {
  const [hoveredSlide, setHoveredSlide] = useState<number | null>(null);
  const { data: featuredArtists, isLoading } = useQuery(
    'featuredArtists',
    ArtistsService.getFeaturedArtistsApiArtistsFeaturedGet,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  );
  if (isLoading) {
    return (
      <Stack direction="column" spacing={2} sx={{ width: 'auto' }}>
        {[...Array(3)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            sx={{
              borderRadius: '16px',
              maxWidth: { xs: '100%', md: 373 },
              height: { xs: 160, md: 160 },
              width: '100%'
            }}
          />
        ))}
      </Stack>
    );
  }

  const handleSlideMouseOver = (trackId: number) => {
    setHoveredSlide(trackId);
  };

  const handleSlideMouseOut = () => {
    setHoveredSlide(null);
  };

  return (
    <Box display="flex" width="100%">
      <Swiper
        style={{
          width: '100%',
          height: 500
        }}
        direction={'vertical'}
        modules={[Autoplay, Pagination]}
        autoplay={{ delay: 5000 }}
        slidesPerView={3}
        spaceBetween={10}
        touchReleaseOnEdges={true}
      >
        {featuredArtists?.map((artist, key) => (
          <SwiperSlide
            onMouseOver={() => handleSlideMouseOver(artist.id || 0)}
            onMouseOut={handleSlideMouseOut}
            key={key}
          >
            <Link
              to={`/artist/${artist.id}/${slugify(artist.profile_name)}`}
              style={{
                textDecoration: 'none',
                color: 'inherit'
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: 160,
                  overflow: 'hidden',
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'start',
                  borderRadius: '16px'
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    backgroundColor:
                      hoveredSlide === artist.id ? 'transparent' : 'rgba(0, 0, 0, 0.2)',
                    transition: 'background-color 0.2s ease',
                    width: '100%',
                    height: '100%',
                    zIndex: 1
                  }}
                ></Box>
                <Avatar
                  variant="square"
                  src={
                    artist.avatar_url && generateCldUrl(artist.avatar_url || '', 'featured_artist')
                  }
                  alt={artist.profile_name}
                  style={{
                    width: '100%',
                    height: '100%',
                    color: '#fff',
                    backgroundColor: '#0000003f'
                  }}
                />
                <Paper
                  sx={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    background: '#0000003f',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    borderRadius: 0,
                    gapX: 2,
                    px: 2,
                    py: 2,
                    height: hoveredSlide === artist.id ? 40 : 0,
                    transition: 'height 0.2s ease'
                  }}
                >
                  <Typography color="#ffffffee" variant="body1">
                    {artist.profile_name}
                  </Typography>
                </Paper>
              </Box>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
