import { useForm, Controller } from 'react-hook-form';
import { Button, TextField, Typography, Container, Box, Grid, Paper, Link } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthService, LoginForm, LoginResponse } from '../client';
import MuzaLogo from '../components/MuzaLogo';
import { loginSchema } from '../components/loginSchema';
import { yupResolver } from '@hookform/resolvers/yup';

interface LoginFormData {
  email: string;
  password: string;
}

export default function LoginPage() {
  const { handleSubmit, control, formState } = useForm<LoginFormData>({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const { errors } = formState;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const redirectPath = searchParams.get('next') || '/';

  const loginMutation = useMutation<LoginResponse, Error, LoginForm>(
    AuthService.loginApiAuthLoginPost,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['currentUser']);
        queryClient.invalidateQueries(['profile']);
        navigate(redirectPath);
      }
    }
  );

  const onSubmit = async (data: LoginFormData) => {
    try {
      await loginMutation.mutateAsync(data);
    } catch (error) {
      // Handle error, e.g., show error message
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingY: 15
      }}
    >
      <Paper
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          component="h1"
          variant="h4"
          sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
        >
          Log in to <MuzaLogo />
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email Address"
                fullWidth
                margin="normal"
                autoFocus
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="password"
                label="Password"
                fullWidth
                margin="normal"
                error={!!errors.password}
                helperText={errors.password?.message}
              />
            )}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} size="large">
            Sign In
          </Button>
          {loginMutation.isError && (
            <Typography color="error">Error logging in. Please try again.</Typography>
          )}
          <Grid container>
            <Grid item xs>
              <Typography variant="subtitle1" gutterBottom>
                Don't have an account?{' '}
                <Link href={'/signup'} underline="none">
                  Sign up
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <Link href="/forgot-password" underline="none">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}
