import React, { useEffect, useState, useRef } from 'react';
import { Typography } from '@mui/material';
import WaveSurfer from 'wavesurfer.js/dist/wavesurfer';

interface TimerProps {
  getPlayer: () => WaveSurfer | null;
}

const Timer: React.FC<TimerProps> = ({ getPlayer }) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const animationRef = useRef<number | null>(null);

  useEffect(() => {
    const player = getPlayer();
    if (!player) return;

    setDuration(player.getDuration());

    const updateCurrentTime = () => {
      const currentPlayer = getPlayer();
      if (currentPlayer) {
        setCurrentTime(currentPlayer.getCurrentTime());
        animationRef.current = requestAnimationFrame(updateCurrentTime);
      }
    };

    animationRef.current = requestAnimationFrame(updateCurrentTime);

    return () => {
      if (animationRef.current !== null) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [getPlayer]);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <Typography variant="body2" sx={{ mt: 1 }}>
      <span style={{ color: '#FF8A8A' }}>{formatTime(currentTime)}</span> /{formatTime(duration)}
    </Typography>
  );
};

export default Timer;
