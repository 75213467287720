import { useEffect, useState } from 'react';
import WavesurferPlayer from '@wavesurfer/react';
import WaveSurfer from 'wavesurfer.js';
import { Box, Grid, IconButton } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';

import { useAudioWavePlayer } from '../../context/AudioWavePlayerContext';
import Hover from 'wavesurfer.js/dist/plugins/hover';
import PlayerTimer from '../PlayerTimer';

interface SubmissionRowPlayerProps {
  src: string;
  submission_id: number;
  handlePlayPause: (submission_id: number) => void;
}
export const SubmissionRowPlayer = ({
  src,
  submission_id,
  handlePlayPause
}: SubmissionRowPlayerProps) => {
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const { isPlaying: isCurrentPlaying } = useAudioWavePlayer();

  useEffect(() => {
    if (isCurrentPlaying !== submission_id && isPlaying) {
      wavesurfer?.pause();
    }
  }, [isCurrentPlaying, wavesurfer]);

  const onReady = async (ws: WaveSurfer) => {
    setWavesurfer(ws);
    setIsPlaying(false);
    ws.registerPlugin(
      Hover.create({
        lineColor: '#FF8A8A',
        lineWidth: 2,
        labelBackground: '#555',
        labelColor: '#fff',
        labelSize: '11px'
      })
    );
  };

  const onPlayPause = () => {
    if (!wavesurfer) return;

    wavesurfer.playPause();
    handlePlayPause?.(submission_id);
  };

  const onPlay = () => {
    if (!wavesurfer) return;

    if (!isPlaying) {
      wavesurfer.playPause();
      handlePlayPause?.(submission_id);
    }
  };

  return (
    <Grid container spacing={3} alignItems="center" sx={{ position: 'relative' }}>
      <Grid item xs={2} justifyContent="center">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <IconButton
            aria-label={isPlaying ? 'pause' : 'play'}
            onClick={onPlayPause}
            sx={{ width: 'fit-content' }}
          >
            {isPlaying ? (
              <PauseCircleIcon sx={{ fontSize: { xs: '3rem', sm: '3.4rem' } }} />
            ) : (
              <PlayCircleIcon sx={{ fontSize: { xs: '3rem', sm: '3.4rem' } }} />
            )}
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={10}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <WavesurferPlayer
            key={submission_id}
            height={46}
            waveColor="#B1A8AB"
            progressColor="#FF5757"
            url={src}
            onReady={async (ws) => await onReady(ws)}
            onClick={onPlay}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            barWidth={4}
            barGap={4}
            barRadius={6}
            backend="WebAudio"
          />
        </Box>
      </Grid>
      <Box sx={{ position: 'absolute', left: 75, bottom: -15 }}>
        <PlayerTimer getPlayer={() => wavesurfer} />
      </Box>
    </Grid>
  );
};
