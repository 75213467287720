import { Outlet, useLocation } from 'react-router-dom';
import NavBar from '../components/NavBar';
import NotificationBar from '../components/NotificationBar';
import { SnackBarProvider } from '../context/SnackBarContext';
import { PlaylistProvider } from '../context/PlaylistProvider';
import Footer from '../components/Footer';
import { Box, Link, Toolbar } from '@mui/material';
import { AudioWavePlayerProvider } from '../context/AudioWavePlayerContext';
import { useProfile } from '../hooks/useProfile';
import { Loader } from '../components/Loader';
import { useAuth } from '../context/AuthProvider';

export const accountTypePages: { [key: string]: string[] } = {
  free: [
    '/',
    '/login',
    '/signup',
    '/forgot-password',
    '/reset-password',
    '/profile',
    '/profile/details',
    '/profile/downloads',
    '/profile/billing',
    '/pricing',
    '/privacy',
    '/terms',
    '/track/*',
    '/artist/*',
    '/search',
    '/search/artists',
    '/search/tracks',
    '/test-upload'
  ],
  premium: [
    '/',
    '/login',
    '/signup',
    '/forgot-password',
    '/reset-password',
    '/profile',
    '/profile/details',
    '/profile/downloads',
    '/profile/billing',
    '/profile/submissions-sent',
    '/pricing',
    '/privacy',
    '/track/*',
    '/artist/*',
    '/search',
    '/search/artists',
    '/search/tracks',
    '/test-upload'
  ],
  pro: [
    '/',
    '/login',
    '/signup',
    '/forgot-password',
    '/reset-password',
    '/profile',
    '/profile/details',
    '/profile/uploads',
    '/profile/upload-music',
    '/profile/uploads/*',
    '/profile/upcoming-projects',
    '/profile/upcoming-projects/add',
    '/profile/downloads',
    '/profile/billing',
    '/profile/submissions-sent',
    '/profile/submissions-received',
    '/pricing',
    '/privacy',
    '/terms',
    '/track/*',
    '/artist/*',
    '/search',
    '/search/artists',
    '/search/tracks',
    '/test-upload'
  ],
  admin: [
    '/',
    '/login',
    '/signup',
    '/forgot-password',
    '/reset-password',
    '/profile',
    '/profile/details',
    '/profile/uploads',
    '/profile/upload-music',
    '/profile/uploads/*',
    '/profile/upcoming-projects',
    '/profile/upcoming-projects/add',
    '/profile/downloads',
    '/profile/billing',
    '/profile/submissions-sent',
    '/profile/submissions-received',
    '/impersonate',
    '/pricing',
    '/privacy',
    '/terms',
    '/track/*',
    '/artist/*',
    '/search',
    '/search/artists',
    '/search/tracks',
    '/test-upload',
  ]
};

export default function Layout() {
  const location = useLocation();
  let { isAuthenticated } = useAuth();
  const { data: profile, isLoading } = useProfile();

  const isPathAllowed = accountTypePages[profile?.plan || 'free'].some((allowedPath) => {
    if (allowedPath.endsWith('/*')) {
      return location.pathname.startsWith(allowedPath.slice(0, -2));
    }
    return location.pathname === allowedPath;
  });

  return (
    <SnackBarProvider>
      <PlaylistProvider>
        <AudioWavePlayerProvider>
          <NavBar />
          <Toolbar />
          <NotificationBar />
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, minHeight: '100vh' }}>
            <main style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
              {isPathAllowed ? (
                <Outlet />
              ) : isLoading ? (
                <Loader />
              ) : (
                isAuthenticated && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    flexGrow={1}
                    p={2}
                  >
                    <h2>Upgrade to access this page</h2>
                    <p>
                      You need to <Link href="/pricing">upgrade</Link> your account to access this
                      page.
                    </p>
                  </Box>
                )
              )}
            </main>
            <Footer />
          </Box>
        </AudioWavePlayerProvider>
      </PlaylistProvider>
    </SnackBarProvider>
  );
}
