/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_update_profile_api_auth_profile_put } from '../models/Body_update_profile_api_auth_profile_put';
import type { CurrentUserResponse } from '../models/CurrentUserResponse';
import type { LoginForm } from '../models/LoginForm';
import type { LoginResponse } from '../models/LoginResponse';
import type { SignupForm } from '../models/SignupForm';
import type { User } from '../models/User';
import type { UserProfile } from '../models/UserProfile';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Current User
     * @returns CurrentUserResponse Successful Response
     * @throws ApiError
     */
    public static currentUserApiAuthCurrentGet(): CancelablePromise<CurrentUserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/current',
        });
    }

    /**
     * Login
     * @param requestBody
     * @returns LoginResponse Successful Response
     * @throws ApiError
     */
    public static loginApiAuthLoginPost(
        requestBody: LoginForm,
    ): CancelablePromise<LoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Logout
     * @returns any Successful Response
     * @throws ApiError
     */
    public static logoutApiAuthLogoutGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/logout',
        });
    }

    /**
     * Signup
     * @param requestBody
     * @returns LoginResponse Successful Response
     * @throws ApiError
     */
    public static signupApiAuthSignupPost(
        requestBody: SignupForm,
    ): CancelablePromise<LoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/signup',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Profile
     * @returns UserProfile Successful Response
     * @throws ApiError
     */
    public static profileApiAuthProfileGet(): CancelablePromise<UserProfile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/profile',
        });
    }

    /**
     * Update Profile
     * @param formData
     * @returns UserProfile Successful Response
     * @throws ApiError
     */
    public static updateProfileApiAuthProfilePut(
        formData: Body_update_profile_api_auth_profile_put,
    ): CancelablePromise<UserProfile> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/auth/profile',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Hide Profile
     * @returns any Successful Response
     * @throws ApiError
     */
    public static hideProfileApiAuthHideProfilePost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/hide-profile',
        });
    }

    /**
     * Confirm Email
     * @param token
     * @returns any Successful Response
     * @throws ApiError
     */
    public static confirmEmailApiAuthConfirmEmailGet(
        token: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/confirm-email',
            query: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Forgot Password
     * @param email
     * @returns any Successful Response
     * @throws ApiError
     */
    public static forgotPasswordApiAuthForgotPasswordPost(
        email: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/forgot-password',
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reset Password
     * @param token
     * @param newPassword
     * @returns any Successful Response
     * @throws ApiError
     */
    public static resetPasswordApiAuthResetPasswordPost(
        token: string,
        newPassword: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/reset-password',
            query: {
                'token': token,
                'new_password': newPassword,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unsubscribe
     * @param token
     * @returns any Successful Response
     * @throws ApiError
     */
    public static unsubscribeApiAuthUnsubscribeGet(
        token: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/unsubscribe',
            query: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Impersonate User
     * @param targetUserId
     * @returns LoginResponse Successful Response
     * @throws ApiError
     */
    public static impersonateUserApiAuthImpersonateUserPost(
        targetUserId: number,
    ): CancelablePromise<LoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/impersonate/user',
            query: {
                'target_user_id': targetUserId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Emails
     * @param query
     * @returns User Successful Response
     * @throws ApiError
     */
    public static searchEmailsApiAuthSearchEmailsGet(
        query: string,
    ): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/search/emails',
            query: {
                'query': query,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
