import { Box, ListItemIcon, Tooltip } from '@mui/material';
import { Stars, Verified } from '@mui/icons-material';
import { Container, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useProfile } from '../../hooks/useProfile';

interface MenuItem {
  to: string;
  label: string;
  plan?: string;
}

const menuItems: MenuItem[] = [
  { to: 'details', label: 'My Details' },
  { to: 'downloads', label: 'Downloads' },
  { to: 'uploads', label: 'Uploads', plan: 'pro' },
  { to: 'upcoming-projects', label: 'Upcoming Projects', plan: 'pro'},
  { to: 'submissions-sent', label: 'Submissions Sent', plan: 'advanced' },
  { to: 'submissions-received', label: 'Submissions Received', plan: 'pro' },
  { to: 'billing', label: 'Billing' }
];

export default function Profile() {
  const location = useLocation();
  const isActive = (path: string) => location.pathname.includes(path);
  const { data: profile } = useProfile();

  const renderListItem = (item: MenuItem) => {
    const isCurrentAdvanced = profile?.plan === 'premium';
    const isCurrentProOrAdmin = profile?.plan === 'pro' || profile?.plan === 'admin';

    if (item.plan === 'pro' && !isCurrentProOrAdmin) {
      return (
        <ListItemButton component={Link} to={item.to} selected={isActive(item.to)}>
          <ListItemText primary={item.label} />
          <ListItemIcon>
            <Tooltip title="Pro feature" placement="right">
              <Stars color="primary" />
            </Tooltip>
          </ListItemIcon>
        </ListItemButton>
      );
    } else if (item.plan === 'advanced' && !isCurrentAdvanced && !isCurrentProOrAdmin) {
      return (
        <ListItemButton component={Link} to={item.to} selected={isActive(item.to)}>
          <ListItemText primary={item.label} />
          <ListItemIcon>
            <Tooltip title="Advanced feature" placement="right">
              <Verified color="primary" />
            </Tooltip>
          </ListItemIcon>
        </ListItemButton>
      );
    } else {
      return (
        <ListItemButton component={Link} to={item.to} selected={isActive(item.to)}>
          <ListItemText primary={item.label} />
        </ListItemButton>
      );
    }
  };

  return (
    <Container maxWidth="xl">
      <Box
        component="aside"
        sx={{
          position: 'fixed',
          width: '300px',
          height: '100vh',
          display: { xs: 'none', md: 'block' }
        }}
      >
        <List sx={{ mt: 2 }}>
          {menuItems.map((item) => (
            <ListItem disablePadding key={item.to}>
              {renderListItem(item)}
            </ListItem>
          ))}
        </List>
      </Box>
      <Box component="main" sx={{ width: { md: 'calc(100% - 300px)' }, ml: { md: '300px' } }}>
        <Outlet />
      </Box>
    </Container>
  );
}
