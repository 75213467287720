import * as yup from 'yup';

export const profileSchema = yup.object({
  avatar: yup.mixed(),
  profile_name: yup.string().required('Profile Name is required'),
  personal_name: yup.string().required('Full Name required'),
  country_code: yup.string().required('Country is required'),
  daw: yup.string(),
  email: yup.string().required('Email is required'),
  about: yup.string(),
  instagram_url: yup.string(),
  spotify_url: yup.string(),
  facebook_url: yup.string(),
  is_hidden: yup.boolean(),
});
