import { useQuery } from 'react-query';

import 'swiper/css';
import 'swiper/css/pagination';

import { TracksCarousel } from '../TracksCarousel';
import { TracksService } from '../../client';
import { Skeleton } from '@mui/material';

export default function MostDownloadedTracks() {
  const { data: mostDownloadedTracks, isLoading } = useQuery(
    'mostDownloadedTracks',
    TracksService.getMostDownloadedTracksApiTracksGetMostDownloadedTracksGet,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  );

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          borderRadius: '8px',
          minHeight: { xs: 300, md: 156 },
          width: '100%'
        }}
      />
    );
  }

  if (!mostDownloadedTracks) {
    return null;
  }

  return <TracksCarousel tracks={mostDownloadedTracks} />;
}
