import { useEffect, useRef, useState, memo } from 'react';
import WavesurferPlayer from '@wavesurfer/react';
import WaveSurfer from 'wavesurfer.js';
import { Box, IconButton } from '@mui/material';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { Link } from 'react-router-dom';
import { generateCldUrl, removeExtension } from '../utils';
import { useAudioWavePlayer } from '../context/AudioWavePlayerContext';
import slugify from 'react-slugify';
import Hover from 'wavesurfer.js/dist/plugins/hover';
import PlayerTimer from './PlayerTimer';

interface AudioWavePlayerProps {
  src: string;
  track_id: number;
  track_name: string;
  track_artist?: string;
  track_artist_id?: number;
  track_image?: string;
  handlePlayPause?: (track_id: number) => void;
}

export const AudioWavePlayer = memo(
  ({
    src,
    track_id,
    track_name,
    track_artist,
    track_artist_id,
    track_image,
    handlePlayPause
  }: AudioWavePlayerProps) => {
    const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const { isPlaying: isCurrentPlaying } = useAudioWavePlayer();

    useEffect(() => {
      if (isCurrentPlaying !== track_id && isPlaying) {
        wavesurfer?.pause();
      }
    }, [isCurrentPlaying, wavesurfer]);

    const imgRef = useRef<HTMLImageElement>(null);

    const handleMouseOver = () => {
      if (imgRef.current) {
        imgRef.current.style.filter = 'grayscale(0%)';
      }
    };

    const handleMouseOut = () => {
      if (imgRef.current) {
        imgRef.current.style.filter = 'grayscale(100%)';
      }
    };

    const onReady = (ws: WaveSurfer) => {
      setWavesurfer(ws);
      setIsPlaying(false);

      ws.registerPlugin(
        Hover.create({
          lineColor: '#FF8A8A',
          lineWidth: 2,
          labelBackground: '#555',
          labelColor: '#fff',
          labelSize: '11px'
        })
      );
    };

    const onPlayPause = () => {
      if (!wavesurfer) return;

      wavesurfer.playPause();
      handlePlayPause?.(track_id);
    };

    const onPlay = () => {
      if (!wavesurfer) return;

      if (!isPlaying) {
        wavesurfer.playPause();
        handlePlayPause?.(track_id);
      }
    };

    const trackNameWithoutExt = removeExtension(track_name);

    const cld_track_image = track_image && generateCldUrl(track_image || '', 'thumbnail');

    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4 }}>
        <Box sx={{ overflow: 'hidden', width: 50, flexShrink: 0 }}>
          <Link to={`/track/${track_id}/${slugify(trackNameWithoutExt)}`}>
            {cld_track_image ? (
              <img
                ref={imgRef}
                src={cld_track_image}
                alt="track"
                style={{
                  objectFit: 'cover',
                  width: 50,
                  height: 50,
                  filter: 'grayscale(100%)',
                  transition: 'filter 0.3s ease'
                }}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              />
            ) : (
              <AudioFileIcon sx={{ width: 50, height: 50, color: 'text.secondary' }} />
            )}
          </Link>
        </Box>
        <IconButton aria-label={isPlaying ? 'pause' : 'play'} onClick={onPlayPause}>
          {isPlaying ? (
            <PauseCircleIcon sx={{ fontSize: { xs: '3rem', sm: '3.4rem' } }} />
          ) : (
            <PlayCircleIcon sx={{ fontSize: { xs: '3rem', sm: '3.4rem' } }} />
          )}
        </IconButton>
        <Box sx={{ maxWidth: 300, width: '100%', display: 'flex', flexDirection: 'column' }}>
          <Link
            to={`/track/${track_id}/${slugify(trackNameWithoutExt)}`}
            style={{ textDecoration: 'none', color: '#ffffffff' }}
          >
            <span>{trackNameWithoutExt}</span>
          </Link>
          <Link
            to={`/artist/${track_artist_id}/${slugify(track_artist || '')}`}
            style={{ textDecoration: 'none', color: '#ffffff9f' }}
          >
            {track_artist}
          </Link>
        </Box>
        <Box sx={{ maxWidth: 320, minWidth: 150, width: '100%' }}>
          <WavesurferPlayer
            height={40}
            waveColor="#B1A8AB"
            progressColor="#FF5757"
            url={src}
            onReady={onReady}
            onClick={onPlay}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            barWidth={3}
            barGap={2}
            barRadius={6}
          />
          {wavesurfer && <PlayerTimer getPlayer={() => wavesurfer} />}
        </Box>
      </Box>
    );
  }
);
