import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Paper,
  FormControlLabel,
  Checkbox,
  Grid,
  Link
} from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import MuzaLogo from '../components/MuzaLogo';
import { signUpSchema } from '../components/signupSchema';

interface SignUpFormData {
  email: string;
  password: string;
  verifyPassword: string;
  personalName: string;
  profileName: string;
  receiveEmails?: boolean;
}

export default function SignupPage() {
  const { handleSubmit, control, formState } = useForm<SignUpFormData>({
    resolver: yupResolver(signUpSchema),
    defaultValues: {
      email: '',
      password: '',
      verifyPassword: '',
      personalName: '',
      profileName: '',
      receiveEmails: false
    }
  });

  const { errors } = formState;
  const navigate = useNavigate();
  const confirm = useConfirm();

  const signUpMutation = useMutation(
    (newUser: {
      email: string;
      password: string;
      personal_name: string;
      profile_name: string;
      receive_emails: boolean;
    }) => axios.post('/api/auth/signup', newUser),
    {
      onSuccess: () => {
        navigate('/');
      },
      onError: async (error: any) => {
        if (error.response?.status === 409) {
          await confirm({
            title: 'Error',
            description: 'E-mail already exists!',
            hideCancelButton: true
          });
        }
      }
    }
  );

  const onSubmit = (data: SignUpFormData) => {
    signUpMutation.mutate({
      email: data.email,
      password: data.password,
      personal_name: data.personalName,
      profile_name: data.profileName,
      receive_emails: data.receiveEmails ?? false
    });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingY: 15
      }}
    >
      <Paper
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          component="h1"
          variant="h4"
          sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
        >
          Sign up to <MuzaLogo />
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
          <Controller
            name="personalName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                label="Full Name"
                fullWidth
                margin="normal"
                error={!!errors.personalName}
                helperText={errors.personalName?.message}
              />
            )}
          />
          <Controller
            name="profileName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                label="Profile Name"
                fullWidth
                margin="normal"
                error={!!errors.profileName}
                helperText={errors.profileName?.message}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                label="Email Address"
                fullWidth
                margin="normal"
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                type="password"
                label="Password"
                fullWidth
                margin="normal"
                error={!!errors.password}
                helperText={errors.password?.message}
              />
            )}
          />
          <Controller
            name="verifyPassword"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                type="password"
                label="Verify Password"
                fullWidth
                margin="normal"
                error={!!errors.verifyPassword}
                helperText={errors.verifyPassword?.message}
              />
            )}
          />
          <Controller
            name="receiveEmails"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} color="primary" />}
                label="I agree to receive emails from Muza"
              />
            )}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} size="large">
            Sign Up
          </Button>
          {signUpMutation.isError && (
            <Typography color="error" variant="body2">
              Error signing up. Please try again.
            </Typography>
          )}
          <Grid container>
            <Grid item xs>
              <Typography variant="subtitle1" gutterBottom>
                Already have an account?{' '}
                <Link href={'/login'} underline="none">
                  Sign in
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}
