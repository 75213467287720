import { Route, Routes } from 'react-router-dom';

import Search from './Search/Search';
import Layout from './Layout';
import Login from './Login';
import SignUp from './SignUp';
import Home from './Home';
import ProtectedRoute from '../components/ProtectedRoute';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import Profile from './profile/Profile';
import ProfileDetails from './profile/ProfileDetails';
import ProfileUploads from './profile/ProfileUploads/ProfileUploads';
import ProfileBilling from './profile/ProfileBilling';
import ProfileDownloads from './profile/ProfileDownloads/ProfileDownloads';
import ProfileMusicUpload from './profile/ProfileUploads/ProfileMusicUpload';
import ArtistDetails from './artist/ArtistDetails';
import TrackDetails from './track/TrackDetails';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

import { AuthProvider } from '../context/AuthProvider';
import Pricing from './Pricing';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ProfileSubmissionsSent from './profile/ProfileSubmissionsSent';
import ProfileSubmissionsReceived from './profile/ProfileSubmissionsReceived';
import ProfileUpcoming from './profile/ProfileUpcoming/ProfileUpcoming';
import ProfileUpcomingAdd from './profile/ProfileUpcoming/ProfileUpcomingAdd';
import Impersonate from './profile/Impersonate';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  'pk_live_51PeiTQJ6IOludSBkjWM8TnNnYByyZwFpIs5LlF3UG6UJb3j7X3Q9KR1YaOwLSNitw9TsQIxHTYlpgtzxKkseSv9U00Lvo9qtvO'
);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <AuthProvider>
        <Routes>
          <Route element={<Layout />}>
            <Route element={<ProtectedRoute />}>
              <Route path="profile" element={<Profile />}>
                <Route path="details" element={<ProfileDetails />} />
                <Route path="uploads" element={<ProfileUploads />} />
                <Route path="upcoming-projects" element={<ProfileUpcoming />} />
                <Route path="upcoming-projects/add" element={<ProfileUpcomingAdd />} />
                <Route path="upcoming-projects/:trackId/edit" element={<ProfileUpcomingAdd />} />
                <Route path="upload-music" element={<ProfileMusicUpload />} />
                <Route path="uploads/:trackId/edit" element={<ProfileMusicUpload />} />
                <Route path="downloads" element={<ProfileDownloads />} />
                <Route path="submissions-sent" element={<ProfileSubmissionsSent />} />
                <Route path="submissions-received" element={<ProfileSubmissionsReceived />} />
                <Route path="billing" element={<ProfileBilling />} />
              </Route>
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route path="impersonate" element={<Impersonate />} />
            </Route>
            <Route path="artist/:artistId/:slug" element={<ArtistDetails />} />
            <Route path="track/:trackId/:slug" element={<TrackDetails />} />
            <Route path="search" element={<Search />} />
            <Route path="search/:searchType" element={<Search />} />
            <Route index element={<Home />} />
            <Route path="terms" element={<TermsOfService />} />
            <Route path="privacy" element={<PrivacyPolicy />} />
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="pricing" element={<Pricing />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Elements>
  );
}

export default App;
