import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/App';
import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { ConfirmProvider } from 'material-ui-confirm';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import ScrollToTop from './components/ScrollToTop';
import ScrollToHash from './components/ScrollToHash';

import * as Sentry from '@sentry/react';

const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

Sentry.init({
  dsn: 'https://06e0742a99be4790f85d44194cb42dc4@o4507808820887552.ingest.de.sentry.io/4507813712363600',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/muza\.website\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: !development
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

declare module '@mui/material/styles' {
  interface Palette {
    primaryTransparent: SimplePaletteColorOptions;
  }

  interface SimplePaletteColorOptions {
    main: string;
  }

  interface PaletteOptions {
    primaryTransparent?: SimplePaletteColorOptions;
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    primaryTransparent: true;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: 'Popins, sans-serif'
  },
  palette: {
    mode: 'dark',
    primary: {
      dark: '#FF8A8A',
      main: '#FF5757',
      light: '#CC4646'
    },
    secondary: {
      dark: '#3321BC',
      main: '#F1066C',
      light: '#442bff'
    },
    primaryTransparent: {
      main: '#FF57578f'
    },
    background: {
      paper: 'rgba(255,255,255,0.1)'
    }
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          backdropFilter: 'blur(10px)'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 800
        },
        h2: {
          fontWeight: 600
        },
        h3: {
          fontWeight: 800,
          textTransform: 'uppercase'
        },
        h4: {
          fontWeight: 800,
          textTransform: 'uppercase',
          fontSize: '1.75rem'
        },
        h5: {
          fontWeight: 800,
          textTransform: 'uppercase',
          fontSize: '1.5rem'
        },
        h6: {
          fontWeight: 800,
          textTransform: 'uppercase',
          fontSize: '1.25rem'
        }
      }
    },
    MuiChip: {
      defaultProps: {
        style: {
          borderRadius: 1
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        style: {
          textTransform: 'initial'
        }
      }
    }
  }
});

root.render(
  <QueryClientProvider client={queryClient}>
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <React.StrictMode>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ConfirmProvider
              defaultOptions={{
                confirmationButtonProps: { variant: 'outlined', color: 'inherit' },
                cancellationButtonProps: { variant: 'outlined', color: 'inherit' }
              }}
            >
              <ScrollToTop />
              <ScrollToHash />
              <App />
            </ConfirmProvider>
          </ThemeProvider>
        </BrowserRouter>
      </React.StrictMode>
    </LocalizationProvider>
  </QueryClientProvider>
);
