import * as yup from 'yup';

export const signUpSchema = yup.object({
  personalName: yup.string().required('Full name is required'),
  profileName: yup.string().required('Profile name is required'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  verifyPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Confirm your password'),
  receiveEmails: yup.boolean()
});
