import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from 'react-query';
import { Button, Grid } from '@mui/material';

import { useCurrentSubscription } from '../../hooks/useCurrentSubscription';
import { UnsubscribeDialog } from './UnsubscribeDialog';
import { PaymentsService, UserProfile } from '../../client';

interface PricingActionButtonsProps {
  profile: UserProfile | undefined;
  planInterval: string | undefined;
  isAnnual: boolean;
  isLoading: boolean;
}

const PricingActionButtons: React.FC<PricingActionButtonsProps> = ({
  profile,
  planInterval,
  isAnnual,
  isLoading
}) => {
  const navigate = useNavigate();
  const [unsubscribeDialogOpen, setUnsubscribeDialogOpen] = useState(false);

  const { data: subscription, isLoading: isSubscriptionLoading } = useCurrentSubscription();

  const paymentMutation = useMutation(
    PaymentsService.createCheckoutSessionApiPaymentsCreateCheckoutSessionPost,
    {
      onSuccess: (data: string) => {
        try {
          (window as any).fbq && (window as any).fbq('track', 'Subscribe');
        } catch (error) {
          console.error('Failed to track Facebook pixel event', error);
        }
        window.location.href = data;
      },
      onError: (error: Error) => {
        console.error('Payment error', error);
      }
    }
  );

  const updateSubscriptionMutation = useMutation(
    PaymentsService.updateSubscriptionApiPaymentsUpdateSubscriptionPost,
    {
      onSuccess: () => {
        window.location.reload();
      },
      onError: (error: Error) => {
        console.error('Payment error', error);
      }
    }
  );

  const isFreePlanMonthly = profile?.plan === 'free' && planInterval === 'month' && !isAnnual;
  const isFreePlanYearly = profile?.plan === 'free' && planInterval === 'year' && isAnnual;

  const isAdvancedPlanMonthly =
    profile?.plan === 'premium' && planInterval === 'month' && !isAnnual;
  const isAdvancedPlanYearly = profile?.plan === 'premium' && planInterval === 'year' && isAnnual;

  const isProPlanMonthly = profile?.plan === 'pro' && planInterval === 'month' && !isAnnual;
  const isProPlanYearly = profile?.plan === 'pro' && planInterval === 'year' && isAnnual;

  const getFreeButtonText = (): string => {
    if (isLoading || isSubscriptionLoading) return 'Loading...';
    if (profile?.plan === 'free' && planInterval === 'month' && !isAnnual) return 'Current Plan';
    if (profile?.plan === 'free' && planInterval === 'year' && isAnnual) return 'Current Plan';
    if (profile?.plan === 'premium') return 'Downgrade';
    if (profile?.plan === 'pro') return 'Downgrade';
    return 'Upgrade';
  };

  const getAdvancedButtonText = (): string => {
    if (isLoading || isSubscriptionLoading) return 'Loading...';
    if (profile?.plan === 'pro') return 'Downgrade';
    if (profile?.plan === 'premium' && planInterval === 'month' && !isAnnual) return 'Current Plan';
    if (profile?.plan === 'premium' && planInterval === 'year' && isAnnual) return 'Current Plan';
    if (profile?.plan === 'free') return 'Upgrade';
    return 'Upgrade';
  };

  const getProButtonText = (): string => {
    if (isLoading || isSubscriptionLoading) return 'Loading...';
    if (profile?.plan === 'pro' && planInterval === 'month' && !isAnnual) return 'Current Plan';
    if (profile?.plan === 'pro' && planInterval === 'year' && isAnnual) return 'Current Plan';
    return 'Upgrade';
  };

  const getPlanType = (plan: string) => {
    return isAnnual ? `${plan}_yearly` : `${plan}_monthly`;
  };

  const triggerUnsubscribeDialog = () => {
    setUnsubscribeDialogOpen(!unsubscribeDialogOpen);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={3} sx={{ display: { xs: 'none', md: 'inherit' } }}>
        <UnsubscribeDialog
          open={unsubscribeDialogOpen}
          handleClose={triggerUnsubscribeDialog}
          updateSubscriptionMutation={updateSubscriptionMutation}
          planType={getPlanType('free')}
        />
      </Grid>
      <Grid item xs={4} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          id="free"
          variant="outlined"
          onClick={async () => {
            if (!profile) {
              navigate('/login?next=/pricing');
              return;
            }
            const planType = getPlanType('free');

            if (subscription?.plan) {
              triggerUnsubscribeDialog();
            } else {
              await paymentMutation.mutateAsync(planType);
            }
          }}
          disabled={(isFreePlanMonthly && !isAnnual) || (isFreePlanYearly && isAnnual)}
        >
          {getFreeButtonText()}
        </Button>
      </Grid>
      <Grid item xs={4} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          id="advanced"
          variant="contained"
          onClick={async () => {
            if (!profile) {
              navigate('/login?next=/pricing');
              return;
            }
            const planType = getPlanType('premium');

            if (subscription?.plan) {
              await updateSubscriptionMutation.mutateAsync(planType);
            } else {
              await paymentMutation.mutateAsync(planType);
            }
          }}
          disabled={(isAdvancedPlanMonthly && !isAnnual) || (isAdvancedPlanYearly && isAnnual)}
        >
          {getAdvancedButtonText()}
        </Button>
      </Grid>
      <Grid item xs={4} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          id="pro"
          variant="contained"
          onClick={async () => {
            if (!profile) {
              navigate('/login?next=/pricing');
              return;
            }
            const planType = getPlanType('pro');

            if (subscription?.plan) {
              await updateSubscriptionMutation.mutateAsync(planType);
            } else {
              await paymentMutation.mutateAsync(planType);
            }
          }}
          disabled={(isProPlanMonthly && !isAnnual) || (isProPlanYearly && isAnnual)}
        >
          {getProButtonText()}
        </Button>
      </Grid>
    </Grid>
  );
};

export default PricingActionButtons;
