import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Paper,
  CircularProgress,
  Autocomplete
} from '@mui/material';
import { useSnackBarContext } from '../../context/SnackBarContext';
import { AuthService, LoginResponse, User } from '../../client';

export default function Impersonate() {
  const [emailQuery, setEmailQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { createSnackBar } = useSnackBarContext();

  const {
    data: users,
    isLoading: isUserLoading,
    refetch: refetchUsers
  } = useQuery(
    ['searchEmails', emailQuery],
    () => AuthService.searchEmailsApiAuthSearchEmailsGet(emailQuery),
    { enabled: false } // Disable automatic refetch
  );

  useEffect(() => {
    if (emailQuery) {
      refetchUsers();
    }
  }, [emailQuery, refetchUsers]);

  const impersonateUserMutation = useMutation<LoginResponse, Error, { userId: number }>(
    (data) => AuthService.impersonateUserApiAuthImpersonateUserPost(data.userId),
    {
      onSuccess: (loginResponse) => {
        queryClient.invalidateQueries('currentUser');
        queryClient.invalidateQueries('profile');
        navigate('/');
      },
      onError: () => {
        createSnackBar({
          content: 'Impersonation failed, please contact support.',
          autoHide: true,
          severity: 'error'
        });
      }
    }
  );

  const handleImpersonateUser = () => {
    if (selectedUser && selectedUser.id) {
      impersonateUserMutation.mutate({ userId: selectedUser.id });
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
    >
      <Paper
        sx={{
          p: 3,
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          width: '100%'
        }}
      >
        <Typography component="h1" variant="h5">
          Impersonate User
        </Typography>
        <Autocomplete
          fullWidth
          options={users || []}
          getOptionLabel={(option) => option.email}
          loading={isUserLoading}
          onInputChange={(event, value) => setEmailQuery(value)}
          onChange={(event, value) => setSelectedUser(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Email"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isUserLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />

        <Button
          type="button"
          fullWidth
          variant="contained"
          size="large"
          sx={{ mt: 3 }}
          onClick={handleImpersonateUser}
          disabled={!selectedUser || impersonateUserMutation.isLoading}
        >
          {impersonateUserMutation.isLoading ? 'Impersonating...' : 'Impersonate User'}
        </Button>

        {impersonateUserMutation.isError && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            Error impersonating user. Please try again.
          </Typography>
        )}
      </Paper>
    </Container>
  );
}
