import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { Box, IconButton, TablePagination, Tooltip, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';

import {
  ApiError,
  TrackDownloadResponse,
  TrackDownloadsService,
  TrackListResponse,
  TracksService
} from '../../client';
import { Loader } from '../Loader';
import { AudioWavePlayer } from '../AudioWavePlayer';
import { useParams } from 'react-router-dom';
import { useSnackBarContext } from '../../context/SnackBarContext';
import { useAuth } from '../../context/AuthProvider';
import { useAudioWavePlayer } from '../../context/AudioWavePlayerContext';
import { useProfile } from '../../hooks/useProfile';
import SocialPopup from '../SocialPopup';
import slugify from 'react-slugify';
import TrackRow from '../Track/TrackRow';

const ROWS_PER_PAGE = 100;

export default function ArtistTracksList() {
  const { artistId } = useParams();
  // TODO: keep for future pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const { data: tracks, isLoading } = useQuery(
    ['tracks', page, rowsPerPage],
    () =>
      TracksService.getArtistTracksApiTracksArtistArtistIdTracksGet(
        Number(artistId),
        page,
        rowsPerPage
      ),
    {
      keepPreviousData: true
    }
  );

  if (isLoading) return <Loader />;

  if (!tracks || tracks.items.length === 0) return <div>No tracks</div>;

  // TODO: keep for future pagination
  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page + 1);
  };

  // TODO: keep for future pagination
  const handleOnRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      {tracks.items.map((track) => (
        <TrackRow track={track} key={track.id} collaboration/>
      ))}
    </Box>
  );
}
