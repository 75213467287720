export const daws = [
  'Ableton Live',
  'FL Studio',
  'Logic Pro',
  'Pro Tools',
  'Cubase',
  'Reaper',
  'GarageBand',
  'Studio One',
  'Reason',
  'Bitwig Studio',
  'Digital Performer',
  'Tracktion Waveform',
  'Acid Pro',
  'Mixcraft',
  'Samplitude',
  'Nuendo',
  'LMMS',
  'Ardour',
  'Audacity',
  'Renoise',
  'Cakewalk by BandLab',
  'Other'
];
